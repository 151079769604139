import React, { useReducer, useState } from 'react';
import styled from "styled-components";
import {breakpoints} from "../../commons/breakpoints";
import FormLayout from './FormLayout';
import {DataReducer, DefaultData } from './data-reducer';
import FormSteps from './FormSteps';
import Stepper from "./_shared/Stepper";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Section = styled.section`
  max-width: ${props => props.theme.sizes.contentWidth};
  margin: 0 8px;
  padding: 0;
  
  @media ${breakpoints.sm} {
    margin: 0 auto;
  }
  
  @media ${breakpoints.lg} {
    min-height: calc(100vh - 271px);
  }
`;

const SectionWrapper = styled.div`
  @media ${breakpoints.sm} {
    margin: 0 32px;
  }
  
  @media ${breakpoints.lg} {
    margin: 0;
  }
`;

export const Form = () => {
  let [step, setStep] = useState(0);
  const [data, setData] = useReducer(DataReducer, DefaultData);
  let steps = FormSteps(step, setStep, data, setData);

  return (
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <FormLayout>
        <Stepper activeStep={steps[`${step}`].activeStep} accomplish={steps[`${step}`].accomplish} accomplishStep={steps[`${step}`].accomplishStep} />
        <Section>
          <SectionWrapper>{steps[`${step}`].content}</SectionWrapper>
        </Section>
      </FormLayout>
      </GoogleReCaptchaProvider>
  )
};

export default Form
